export const environment = {
    e2e: false,
    production: false,
    firebase: {
        apiKey: "AIzaSyB97KbJQbHTlUOqdPnN2Y6WLmqiSYt81jU",
        authDomain: "dcsptest-sso.service.deloitte.ai",
        projectId: "dcsptest",
    },
    region: "europe-west1",
    brain_base_url: "https://cxsnow.api-test.deloitte.camp",
    authParams: ["oidc.azure"],
    signInType: 'signInWithPopup',
    aoh_url: "https://aoh-qa.49d2e1a524ab972c04691ebc8d6a7f40.cwl.camp",
    store_url: "https://store-qa.49d2e1a524ab972c04691ebc8d6a7f40.cwl.camp",
    api_base_url: "https://cxsnow.api-test.deloitte.camp",
    hasEnterpriseAssetSearch: false,
    asset_redirect_uri: {},
    upload: {
        allowUnclassifiedFilesUpload: true,
        confidentialMetadata: [],
        existingMetadata: []
    },
    onboarding: {
        displayVideo: true,
        video_url: "https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Deloitte%20AI%20Video/Deloitte%20AI%20-%20Asset%20Owner%20Hub.mp4",
        development: {
            frontend_url: "https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Frontend_Development_Kit/Deloitte.AI%20Frontend%20Development%20Kit.pdf",
            backend_url: "https://bitbucket.de.deloitte.com/projects/AI/repos/dcsp-python-framework/browse",
            business_support_email: "mailto:campfire@deloitte.de?subject=Question%20regarding%20Deloitte%20AI%20Marketplace",
        }
    },
    barrierQuestionsAbort: {
        mailAddress: "mahorst@deloitte.de"
    },
    support: {
        ccEmail: "",
        contact: "mailto:campfire@deloitte.de?subject=Question%20regarding%20Deloitte%20AI%20Marketplace"
    },
    onboarding_options: {
        "manual": {},
        "api_passthrough": {},
        "soft_onboarding": {}
    }
};
